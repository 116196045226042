import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { MDXRenderer } from "gatsby-plugin-mdx"
import styled from "styled-components"
import PropTypes from "prop-types"
import SEO from "../components/Seo"

const PostTemplateStyles = styled.section`
  h1,
  h2 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .post__body {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
`

const PostTemplate = ({ data }) => {
  const { title, date, desc, author, image } = data.mdx.frontmatter
  const { body } = data.mdx
  const img = getImage(image.childImageSharp.gatsbyImageData)

  return (
    <Layout>
      <SEO
      title={title}
      description={desc}/>

      <PostTemplateStyles>
        <hr style={{ marginTop: 0 }} class="separator separator__large" />
        <Link className="btn" to="/">
          Back to Home
        </Link>
        <hr class="separator" />
        <h1>{title}</h1>
        <div className="time">Written by {author}</div>
        {/* <span>Written by {author}</span> & Posted on <span>{date}</span> */}
        {/* <GatsbyImage image={img} alt="Blog Post" /> */}
        <div className="post__body">
          <MDXRenderer>{body}</MDXRenderer>
        </div>
        <hr class="separator" />
        <div className="time">Posted on {date}</div>
        {/* <span>className="time" Posted on {date}</span> */}
        
        <hr class="separator separator__large" />
      </PostTemplateStyles>
    </Layout>
  )
}

export const query = graphql`
  query getPost($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        slug
        desc
        date(formatString: "MMMM Do, YYYY")
        author
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
  }
`

export default PostTemplate

PostTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  author: PropTypes.string,
  desc: PropTypes.string,
  image: PropTypes.object,
}
